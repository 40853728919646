.menuComponent {
    color: black;
    font-family: 'Arial', sans-serif;
    position: fixed;      /* Makes the menu stick to the top */
    top: 0;               /* Aligns the menu to the top edge of the viewport */
    left: 0;              /* Aligns the menu to the left edge of the viewport */
    width: 100%;          /* Ensures the menu spans the full width of the viewport */
    z-index: 1000;  
}

.menu-icon {
    display: none; /* Initially hide the icon */
    cursor: pointer;
    padding: 15px;
    color: black;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    font-size:24px;
}

.menuComponent ul {
    color: black;
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    background-color: rgba(51, 51, 51, 0.2);
}

.menuComponent li {
    padding: 15px;
    
}

.menuComponent a {
    color: white;
    text-decoration: none;
   
    transition: color 0.3s;
}

.menuComponent a:hover {
    color: #00aaff;
}

@media (max-width: 468px) {
    .menu-icon {
        display: block; /* Display the icon on smaller screens */
    }
    .menuComponent {
        
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: rgba(51, 51, 51, 0.8);
        color:white;
    }
    

    .menuComponent ul {
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 45px; 
        left: 0;
        background-color: rgba(51, 51, 51, 0.8);
        z-index: 1;
        display: none; /* Hide the menu by default on small screens */
    }

    .menuComponent ul.active {
        display: block; 
        
        /* Display the menu when it's active */
        /* Display the menu when it's active */
    }
}
