/*
	Aerial by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Wrapper */

	#wrapper {
		opacity: 1 !important;
	}

/* Overlay */

	#overlay {
		opacity: 1 !important;
	}

/* Header */

	#header {
		opacity: 1 !important;
	}

		#header nav li {
			opacity: 1 !important;
		}